import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

/**
 * Компонент для управления масштабом страниц в мобильных браузерах
 * @param {number|string} initialScale
 * @param {boolean} userScalable
 * @returns {*}
 * @constructor
 */
const ViewportMeta = ({ initialScale, userScalable }) => {
  return (
    <Helmet>
      <meta
        name="viewport"
        content={`width=device-width, initial-scale=${initialScale}, maximum-scale=1, user-scalable=${
          userScalable ? "yes" : "no"
        }`}
      />
    </Helmet>
  );
};

ViewportMeta.propTypes = {
  initialScale: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userScalable: PropTypes.bool,
};

ViewportMeta.defaultProps = {
  initialScale: 1,
};

export default ViewportMeta;
