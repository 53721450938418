import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import _ from "lodash";
import { makeStyles, TextField, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FlexboxVertical from "../../../../SharedComponents/FlexboxVertical.jsx";
import useEffectAsync from "../../../../hooks/useEffectAsync.js";
import AudioRecorder from "./SharedComponents/AudioRecorder.jsx";
import ErrorText from "../../../../SharedComponents/ErrorText.jsx";
import { useHttp } from "../../../../Providers/Common/HttpProvider.jsx";
import { useDialogs } from "../../../../Providers/Common/DialogsProvider.jsx";
import FlexboxHorizontal from "../../../../SharedComponents/FlexboxHorizontal.jsx";
import { DEFAULT_LEARNED_PATCH } from "../../../../Providers/Data/LearnProcessProvider.jsx";
import Question from "./SharedComponents/Question.jsx";
import useDateUtils from "../../../../hooks/useDateUtils.js";
import { HOMEWORK_STATUS_COLORS, IS_APP_STUDYLESS } from "../../../../constants.js";
import Appendix from "./SharedComponents/Appendix.jsx";
import SimpleAudioPlayer from "../../../../SharedComponents/SimpleAudioPlayer.jsx";
import Exercise7Files from "./Components/Exercise7Files.jsx";
import Exercise7Images from "./Components/Exercise7Images.jsx";
import { useBaseData } from "../../../../Providers/Data/BaseDataProvider.jsx";
import { useAnswerUserFiles } from "../../../../Providers/Data/AnswerUserFilesProvider.jsx";

const StyledTextField = withStyles(() => ({
  root: {
    flexGrow: 1,
    margin: 20,
    backgroundColor: "white",
    "& .MuiInputBase-multiline": {
      flexGrow: 1,
      alignItems: "stretch",
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  homeworkStatusInfo: {
    width: "calc(100% - 60px)",
  },
  statusText: {
    margin: "10px 0 -18px",
    fontWeight: 600,
  },
  teacherComment: {
    margin: "26px 0 0",
    whiteSpace: "pre-wrap",
  },
  teacherImage: {
    maxWidth: "50%",
    borderRadius: 10,
  },
  hr: {
    width: "100%",
    height: 2,
    minHeight: 2,
    marginTop: 40,
    backgroundColor: theme.palette.primary.main,
    border: "none",
  },
}));

// Для ИИ Стадилеса
const minTextAnswerLength = 10;

/**
 * @param {ExerciseType7|ExerciseType7Legacy} exercise
 * @param {Function} setCheckButtonDisabled
 * @param {String} exerciseTitle
 * @param {MutableRefObject<Function>} checkAnswerRef
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
const Exercise7 = ({ exercise, setCheckButtonDisabled, exerciseTitle, checkAnswerRef, ...rest }) => {
  const classes = useStyles();
  const t = useTranslate();
  const { format } = useDateUtils();
  const { post } = useHttp();
  const snackbar = useDialogs();
  const { userInfo, refreshUserInfo } = useBaseData();
  const {
    pictureFiles,
    homeworkUserDataPhotos,
    anyFiles,
    homeworkUserDataFiles,
    resetImage,
    resetFile,
  } = useAnswerUserFiles();
  const { subject, useAiToCheckHomeworks } = userInfo;
  const [isAudioRecordingSupported, setAudioRecordingSupported] = useState(true);
  const [audioRecord, setAudioRecord] = useState(/** @type {?Blob} */ null);
  const [userText, setUserText] = useState("");
  const [approveCode, setApproveCode] = useState("");

  /** @type {ExerciseType7Answer|ExerciseType7Legacy} */
  const answer = exercise.answer || exercise;

  /** @type {Homework} */
  const exerciseHomework = _.get(answer, "homework");
  /** @type {HomeworkUserData} */
  const homeworkUserData = _.get(exerciseHomework, "userData");
  /** @type {HomeworkTeacherData} */
  const homeworkTeacherData = _.get(exerciseHomework, "teacherData");
  let homeworkDate = _.get(homeworkTeacherData, "updatedAt") || _.get(exerciseHomework, "updatedAt");
  if (homeworkDate) {
    homeworkDate = format(new Date(homeworkDate), "dd/MM/yyyy");
  }
  const isTeachersCommentVisible =
    exerciseHomework &&
    ["approved", "not_approved", "not_approved_allow_update", "ai_reviewed"].includes(exerciseHomework.status);

  const editCount =
    typeof exerciseHomework?.editCount === "number" ? exerciseHomework.editCount : answer.editCount || 0;

  // Если для ученика не установлена проверка ДЗ с помощью ИИ, то только тогда ожидающие домашки можно редактировать.
  // (Так было заложено изначально)
  // А если установлено, что проверка домашек должна производиться с помощью ИИ, то статус "wait_review" означает, что
  // домашка уже отправлена на проверку ИИ и редактировать её уже поздно.
  // Специально для стади: если домашка уже проверена ИИ, то её можно отредактировать максимум 1 раз.
  const isAllowedToEdit =
    !exerciseHomework ||
    (exerciseHomework.status === "wait_review" && !userInfo.useAiToCheckHomeworks) ||
    exerciseHomework.status === "not_approved_allow_update" ||
    (IS_APP_STUDYLESS && exerciseHomework.status === "ai_reviewed" && editCount < 1);

  //reset state
  useEffect(() => {
    setUserText("");
    resetImage(answer);
    resetFile(answer);
    setUserText(homeworkUserData ? homeworkUserData.text : "");
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise]);

  // refresh userInfo when exercise changed
  useEffect(() => {
    refreshUserInfo();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercise]);

  //init audio recording if need
  useEffectAsync(async () => {
    if (answer.answerType === "audio") {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        setAudioRecordingSupported(false);
      }
    }
  }, [answer]);

  // manage "check button" state
  useEffect(() => {
    if (!setCheckButtonDisabled) return;
    const userTextNoSpaces = userText.replace(/\s+/g, "");
    const textLengthRequirementsPassed =
      (IS_APP_STUDYLESS && useAiToCheckHomeworks && userTextNoSpaces.length >= minTextAnswerLength) ||
      (IS_APP_STUDYLESS && !useAiToCheckHomeworks && userText.length > 0) ||
      (!IS_APP_STUDYLESS && userText.length > 0);
    if (!homeworkUserData) {
      setCheckButtonDisabled(
        (answer.answerType === "text" &&
          !textLengthRequirementsPassed &&
          pictureFiles.length === 0 &&
          anyFiles.length === 0 &&
          approveCode === "") ||
          (answer.answerType === "audio" && audioRecord === null && approveCode === ""),
      );
    } else {
      if (!isAllowedToEdit) {
        setCheckButtonDisabled(true);
        return;
      }
      if (answer.answerType === "audio") {
        setCheckButtonDisabled(
          pictureFiles.length === 0 &&
            anyFiles.length === 0 &&
            audioRecord === null &&
            homeworkUserData.photos.length === homeworkUserDataPhotos.length &&
            homeworkUserData.files.length === homeworkUserDataFiles.length,
        );
        //eslint-disable-next-line prettier/prettier
      } else {
        // NOT AUDIO (TEXT!)
        const imageCondition1 = pictureFiles.length > 0;
        const imageCondition2 = imageCondition1 || homeworkUserDataPhotos.length !== homeworkUserData.photos.length;
        const filesCondition = anyFiles.length > 0 || homeworkUserDataFiles.length !== homeworkUserData.files.length;
        const textCondition0 = homeworkUserData.text !== userText && textLengthRequirementsPassed;
        const textCondition1 = imageCondition1 && textCondition0;
        const textCondition2 = !imageCondition1 && textCondition0 && userText !== "";
        const codeCondition = approveCode !== "";
        setCheckButtonDisabled(
          !(imageCondition2 || textCondition1 || textCondition2 || codeCondition || filesCondition),
        );
      }
    }
  }, [
    userText,
    pictureFiles,
    anyFiles,
    setCheckButtonDisabled,
    audioRecord,
    answer,
    homeworkUserData,
    homeworkUserDataPhotos,
    homeworkUserDataFiles,
    isAllowedToEdit,
    approveCode,
    useAiToCheckHomeworks,
    exerciseHomework,
  ]);

  if (checkAnswerRef) {
    checkAnswerRef.current = async () => {
      let photos = [];
      let files = [];
      if (homeworkUserData) {
        photos = homeworkUserDataPhotos.map((photo) => ({ name: photo.name, path: photo.path }));
        files = homeworkUserDataFiles.map((file) => ({ name: file.name, path: file.path }));
      }
      if (pictureFiles.length > 0) {
        for (let i = 0; i < pictureFiles.length; i++) {
          const file = pictureFiles[i];
          const formData = new FormData();
          formData.append("file", file, file.name);
          let lastError = "Unknown error";
          /** @type {PostFileResponse} */
          const response = await post("upload/exercises", formData, (error) => {
            lastError = error;
            snackbar.error(error);
          });
          if (response) {
            photos.push({
              name: response.originalname,
              path: response.path,
            });
          } else {
            throw lastError;
          }
        }
      }
      if (anyFiles.length > 0) {
        for (let i = 0; i < anyFiles.length; i++) {
          const file = anyFiles[i];
          const formData = new FormData();
          formData.append("file", file, file.name);
          let lastError = "Unknown error";
          /** @type {PostFileResponse} */
          const response = await post("upload/exercises", formData, (error) => {
            lastError = error;
            snackbar.error(error);
          });
          if (response) {
            files.push({
              name: response.originalname,
              path: response.path,
            });
          } else {
            throw lastError;
          }
        }
      }
      if (answer.answerType === "audio") {
        if (!homeworkUserData || audioRecord) {
          const fileExtension = audioRecord.type.split(";")[0].split("/")[1];
          const formData = new FormData();
          formData.append("file", audioRecord, `record-${Date.now()}.${fileExtension}`);
          let lastError = "Unknown error";
          /** @type {PostFileResponse} */
          const response = await post("upload/exercises", formData, (error) => {
            lastError = error;
            snackbar.error(error);
          });
          if (!response) {
            throw lastError;
          }
          return {
            ...DEFAULT_LEARNED_PATCH,
            payload: {
              text: null,
              audio: {
                name: response.originalname,
                path: response.path,
              },
              photos,
              files,
              codeToApprove: approveCode,
            },
          };
        } else if (homeworkUserData) {
          return {
            ...DEFAULT_LEARNED_PATCH,
            payload: {
              text: null,
              audio: {
                name: homeworkUserData.audio.name,
                path: homeworkUserData.audio.path,
              },
              photos,
              files,
              codeToApprove: approveCode,
            },
          };
        }
      }
      if (answer.answerType === "text") {
        return {
          ...DEFAULT_LEARNED_PATCH,
          payload: {
            text: userText,
            audio: null,
            photos,
            files,
            codeToApprove: approveCode,
          },
        };
      }
    };
  }

  /** @param {?Blob} blob */
  const recorderHandler = (blob) => {
    setAudioRecord(blob);
  };

  /** @param {ReactEventTarget} target */
  const textInputHandler = ({ target }) => {
    const value = target.value;
    setUserText(value);
  };

  /**
   * @param {string} code
   */
  const approveCodeHandler = (code) => {
    setApproveCode(code);
  };

  const textInputHelperText =
    IS_APP_STUDYLESS && useAiToCheckHomeworks && isAllowedToEdit
      ? t("Exercises.type7.limitsHelperText", { count: minTextAnswerLength })
      : undefined;

  return (
    <FlexboxVertical {...rest} data-component="Exercise7" alignItems="center" height="100%" width="100%">
      <Question
        exerciseQuestion={exercise.question}
        exerciseTitle={exerciseTitle}
        defaultTitle={t(`Exercises.type7.title.${answer.answerType}`)}
        disableBottomSeparator={!exerciseHomework}
      />
      {exercise.appendix && exercise.appendix.pdf && <Appendix pdfUrl={exercise.appendix.pdf.url} />}
      <FlexboxVertical className={classes.homeworkStatusInfo}>
        {homeworkUserData && (
          <FlexboxHorizontal
            justifyContent="space-between"
            style={{ color: HOMEWORK_STATUS_COLORS[exerciseHomework.status] }}
          >
            <Typography className={classes.statusText} color="inherit">
              {homeworkDate}
            </Typography>
            <Typography className={classes.statusText} color="inherit">
              {t(`status.${exerciseHomework.status}`)}
            </Typography>
          </FlexboxHorizontal>
        )}
        {homeworkTeacherData && isTeachersCommentVisible && (
          <>
            <Typography
              className={classes.teacherComment}
              dangerouslySetInnerHTML={{ __html: homeworkTeacherData.review }}
            />
            <Typography className={classes.teacherComment}>{homeworkTeacherData.comment}</Typography>
            {homeworkTeacherData.image && (
              <FlexboxVertical alignItems="center" marginTop="10px">
                <img className={classes.teacherImage} src={homeworkTeacherData.image.url} />
              </FlexboxVertical>
            )}
            {homeworkTeacherData.audio && (
              <SimpleAudioPlayer
                src={homeworkTeacherData.audio.url}
                title="Exercises.type7.homework.teacherData.audio"
              />
            )}
            {homeworkTeacherData.audioComment && (
              <SimpleAudioPlayer
                src={homeworkTeacherData.audioComment.url}
                title="Exercises.type7.homework.teacherData.audioComment"
              />
            )}
          </>
        )}
      </FlexboxVertical>
      {(homeworkTeacherData?.review ||
        homeworkTeacherData?.comment ||
        homeworkTeacherData?.image ||
        homeworkTeacherData?.audio ||
        homeworkTeacherData?.audioComment) && <hr className={classes.hr} />}
      <Exercise7Images onCodeInput={approveCodeHandler} answer={answer} isAllowedToEdit={isAllowedToEdit} />
      {subject.hmwksAddnlFlsEnabled && <Exercise7Files answer={answer} isAllowedToEdit={isAllowedToEdit} />}
      <FlexboxVertical data-tag="user data wrapper" flexGrow={1} justifyContent="center" alignSelf="stretch">
        {answer.answerType === "audio" && (
          <>
            {isAudioRecordingSupported && (
              <AudioRecorder onAudioChanged={recorderHandler} answer={answer} isAllowedToEdit={isAllowedToEdit} />
            )}
            {!isAudioRecordingSupported && <ErrorText>{t("Exercises.type7.recordingIsNotSupported")}</ErrorText>}
          </>
        )}
        {answer.answerType === "text" && (
          <StyledTextField
            label={t("Exercises.type7.inputLabel")}
            value={userText}
            onChange={textInputHandler}
            variant="outlined"
            multiline
            helperText={textInputHelperText}
            InputProps={{
              readOnly: !isAllowedToEdit,
            }}
          />
        )}
      </FlexboxVertical>
    </FlexboxVertical>
  );
};

Exercise7.propTypes = {
  checkAnswerRef: PropTypes.object,
  exercise: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string,
  setCheckButtonDisabled: PropTypes.func.isRequired,
};

export default Exercise7;
