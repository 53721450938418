import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import { useBaseData } from "../Data/BaseDataProvider.jsx";
import { useDialogs } from "../Common/DialogsProvider.jsx";
import { useAuth } from "../Auth/AuthProvider.jsx";

const PaymentContext = React.createContext(null);

/**
 * @returns {PaymentContext}
 */
export const usePayment = () => useContext(PaymentContext);

// eslint-disable-next-line require-jsdoc
const PaymentProvider = ({ children }) => {
  const t = useTranslate();
  const { requestPaymentHtml, preselectedOffer, setPreselectedOffer, userInfo, payHtml } = useBaseData();
  const { pathname } = useLocation();
  const snackbar = useDialogs();
  const { user } = useAuth();

  /** */
  const pay = (id) => {
    if (userInfo) {
      const existOffer = userInfo && userInfo.offers.find((offer) => offer.status === "pending");

      if (existOffer && !existOffer.paid && existOffer.offerId !== id) {
        return snackbar.warn(`${t("existOffer")}`);
      }
    }

    requestPaymentHtml(id, "offer").then();
  };

  /** opening payment first time after login or register if offer was chosen */
  useEffect(() => {
    if (user && preselectedOffer) {
      pay(preselectedOffer);
      setPreselectedOffer(null);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedOffer, pathname]);

  // Переход по ссылке на оплату
  useEffect(() => {
    if (payHtml) {
      window.location.href = payHtml.data;
    }
  }, [payHtml]);

  return <PaymentContext.Provider value={{ pay }}>{children}</PaymentContext.Provider>;
};

export default PaymentProvider;
